<template>
  <div class="pa-4">
    <notification-box :flat="true" type="info" class="mb-4">
      <div class="text-label">Your new password must include:</div>
      <ul style="list-style-type: square">
        <li>At least 8 characters</li>
        <li>Uppercase and lowercase letters</li>
        <li>At least one number</li>
        <li>At least one special character: # ? ! @ $ % ^ & * -</li>
      </ul>
    </notification-box>
    <h3 class="mb-2">Current password</h3>
    <v-text-field
      outlined
      type="password"
      v-model="local.currentPassword"
      @blur="showErrors.currentPassword = true"
      @focus="showErrors.currentPassword = false"
      :error-messages="
        showErrors.currentPassword && errorMessages.currentPassword
          ? errorMessages.currentPassword
          : null
      "
    ></v-text-field>

    <h3 class="mb-2">New password</h3>
    <v-text-field
      outlined
      type="password"
      v-model="local.newPassword"
      @blur="showErrors.newPassword = true"
      @focus="showErrors.newPassword = false"
      :error-messages="
        showErrors.newPassword && errorMessages.newPassword
          ? errorMessages.newPassword
          : null
      "
    ></v-text-field>

    <div class="font-weight-medium mb-2">Confirm new password</div>
    <v-text-field
      type="password"
      outlined
      v-model="local.confirmPassword"
      @blur="showErrors.confirmPassword = true"
      @focus="showErrors.confirmPassword = false"
      :error-messages="
        showErrors.confirmPassword && errorMessages.confirmPassword
          ? errorMessages.confirmPassword
          : null
      "
    ></v-text-field>

    <div class="d-flex align-center">
      <v-btn text :disabled="processing" depressed @click="close">{{
        closeLabel
      }}</v-btn>
      <v-spacer />
      <v-btn v-bind="buttonType" depressed @click="submit">Save</v-btn>
    </div>
    <div class="mt-4">
      <div class="text-label">Can't remember your current password?</div>
      You can change your password by logging out and using the 'Reset Password' option on the Log In page.
    </div>
  </div>
</template>
<script>
import { isEmpty, isEqual } from 'lodash'

import NotificationBox from '../../ui/NotificationBox'
export default {
  name: 'UserMenuChangeEmail',
  components: { NotificationBox },
  props: {
    closeLabel: {
      default: 'Close'
    }
  },
  data: function () {
    return {
      local: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      processing: false,
      showErrors: {
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
      }
    }
  },
  computed: {
    errorMessages() {
      const msgs = {
        currentPassword: null,
        newPassword: null,
        confirmPassword: null
      }

      if (this.localFormatted.currentPassword === '') {
        msgs.currentPassword = 'Required field'
      }

      msgs.newPassword = this.validatePassword(this.localFormatted.newPassword)

      if (
        !isEqual(
          this.localFormatted.newPassword,
          this.localFormatted.confirmPassword
        )
      ) {
        msgs.confirmPassword = 'Please confirm your new password'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(
        this.localFormatted.currentPassword,
        this.localFormatted.newPassword
      )
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    localFormatted() {
      return {
        currentPassword: this.local.currentPassword,
        newPassword: this.local.newPassword,
        confirmPassword: this.local.confirmPassword
      }
    }
  },
  methods: {
    validatePassword(v) {
      if (!v) return 'Password is required'

      if (v.length < 8) return 'Password must be at least 8 characters long'

      if (/.*[a-z].*/.test(v) === false)
        return 'Password must contain at least 1 lowercase letter'

      if (/.*[A-Z].*/.test(v) === false)
        return 'Password must contain at least 1 uppercase letter'

      if (/.*[0-9].*/.test(v) === false)
        return 'Password must contain at least 1 number'

      if (/.*[$#?!@$%^&*-].*/.test(v) === false)
        return 'Password must contain at least 1 special character : # ? ! @ $ % ^ & * -'

      return null
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    close() {
      this.$emit('close')
    },
    async submit() {
      this.processing = true
      let result = {}
      if (this.isValid) {
        if (this.isChanged) {
          result = await this.$store.dispatch('account/auth_change_password', {
            currentPassword: this.localFormatted.currentPassword,
            newPassword: this.localFormatted.newPassword
          })
        } else {
          result = {
            type: 'error',
            message: 'Cannot enter your existing password'
          }
        }
      }
      this.showErrorsSwitch()
      this.processing = false
      this.$store.commit('app/SET_USER_MENU_ALERT', result)
      if (!isEmpty(result) && result.type === 'success') {
        this.close()
      }
    }
  }
}
</script>
